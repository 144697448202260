<!--开户-->
<template>
    <el-form ref="form" label-width="100px" size="mini">
        <el-row>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('开户公司')">
                    <!-- <el-input v-model.trim="name" :placeholder="msg('开户公司限四个字符')"></el-input> -->
                    <el-select v-model="companyId" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('开户账号')">
                    <!-- <el-input v-model.trim="userId" :placeholder="msg('请输入账号')"></el-input> -->
                    <el-autocomplete size="mini" clearable
                        v-model="userName"
                        :fetch-suggestions="searchUser"
                        :highlight-first-item="true"
                        :placeholder="this.msg('请输入账号')"
                        :trigger-on-focus="false"
                        @select="selectUser"
                        @clear="clearUsername"
                        ref="userRef"
                        :popper-append-to-body="false"
                    ></el-autocomplete>
                </el-form-item>
            </el-col>
            <!-- <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('开户名')">
                    <el-input v-model.trim="name" :placeholder="msg('字母和数字组合')"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('角色')">
                    <el-input v-model.trim="role" :placeholder="msg('字母和数字组合')"></el-input>
                </el-form-item>
            </el-col> -->
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>
    </el-form>
</template>

<script>
export default {
    name: 'companyEdit',
    data(){
        return {
            options:[],
            companyId:null,
            userId:null,
            userName:null,
            name:null,
            role:null,
        }
    },
    mounted(){
        this.getCompanys();
    },
    methods:{
        init(){
            this.companyId = null;
            this.userId = null;
            this.userName = null;
        },
        getCompanys(){
            this.$axios.post(this.$kit.api.company.listByDataPath,)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        if(data){
                            data.forEach(e => {
                                this.options.push(
                                        {
                                            label:e.name,
                                            value:e.id,
                                        }
                                    )
                            })
                        }
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        searchUser(queryString, cbFn){  //搜索用户
            this.$axios.get(this.$kit.api.user.search+"?name="+encodeURIComponent(queryString)+"&type=1&simple=1")
                .then((result) => {
                    if(result.status){
                        let users =[];
                        result.data.forEach(e => {
                            users.push({
                                id: e.id,
                                value: e.value
                            });
                        });
                        cbFn(users);
                    }
                });
        },
        selectUser(item) { //获取选中的用户
            this.userId = item.id;
            this.userName = item.value;
        },
        clearUsername(){
            this.username = null;
            this.userId = null;
        },
        onSubmit(){
            if(!this.companyId){
                this.$message(this.msg("请选择开户公司"));
                return;
            }
            if(!this.userId){
                this.$message(this.msg("请选择开户账户"));
                return;
            }
            let param = {
                userId:this.userId,
                companyId:this.companyId
            }
            var _this = this;
            this.$axios.post(this.$kit.api.wallet.create, param)
                .then(function(result){
                    if(result.status){
                        _this.$message(result.msg || _this.msg("创建成功"));
                        if(result.status){
                            _this.$emit("close-dg", true);
                        }
                    }else{
                        _this.$message(result.msg ||  _this.msg('创建失败'));
                    }
                });
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/ .el-autocomplete-suggestion{
    width: auto!important;
    }
</style>