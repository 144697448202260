import { render, staticRenderFns } from "./WalletCreate.vue?vue&type=template&id=7c9a1e2e&scoped=true"
import script from "./WalletCreate.vue?vue&type=script&lang=js"
export * from "./WalletCreate.vue?vue&type=script&lang=js"
import style0 from "./WalletCreate.vue?vue&type=style&index=0&id=7c9a1e2e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c9a1e2e",
  null
  
)

export default component.exports