<!--更新信用额度-->
<template>
    <el-form ref="form" label-width="100px" size="mini">
        <el-row>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('信用额度(元)')">
                    <!-- <el-input v-model.trim="loan" :placeholder="msg('输入信用额度')"></el-input> -->
                    <el-input-number v-model="loan" controls-position="right" :min="0"></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('结算密码')">
                    <el-input type="password" show-password v-model.trim="password" :placeholder="msg('输入开户公司结算密码')"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>
    </el-form>
</template>

<script>
export default {
    name: 'companyUpdate',
    data(){
        return {
            options:[],
            loan:0,
            password:null,
        }
    },
    mounted(){
    },
    props:{
        id: Number
    },
    methods:{
        init(){
            this.loan = 0;
            this.password = null;
        },
        onSubmit(){
            if(this.loan < 0){
                this.$message(this.msg("请输入信用额度"));
                return;
            }
            if(!this.password){
                this.$message(this.msg("请输入结算密码"));
                return;
            }
            let param = {
                ids:this.id,
                loan:this.loan,
                password:this.password
            }
            var _this = this;
            this.$axios.post(this.$kit.api.wallet.updateLoan, param)
                .then(function(result){
                    if(result.status){
                        _this.$message(result.msg || _this.msg("创建成功"));
                        if(result.status){
                            _this.$emit("close-dg", true);
                        }
                    }else{
                        _this.$message(result.msg ||  _this.msg('创建失败'));
                    }
                });
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/ .el-autocomplete-suggestion{
    width: auto!important;
    }
</style>