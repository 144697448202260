<!--优惠券赠送-->
<template>
    <el-form ref="form" label-width="100px" size="mini">
        <el-row>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('券名称')">
                    <el-input v-model.trim="name" :placeholder="msg('输入券名')"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('使用重量(KG)')">
                    <el-input-number v-model="weight" controls-position="right" :min="0"></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('优惠金额(元)')">
                    <el-input-number v-model="amount" controls-position="right" :min="0"></el-input-number>
                </el-form-item>
            </el-col>
            <!-- <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('赠送数量(张)')">
                    <el-input-number v-model="count" controls-position="right" :min="0"></el-input-number>
                </el-form-item>
            </el-col> -->
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('过期时间')">
                    <el-date-picker
                        v-model="expireTime"
                        type="date"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('赠送说明')">
                    <el-input v-model="remark" :placeholder="msg('输入赠送说明')"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('结算密码')">
                    <el-input type="password" show-password v-model.trim="password" :placeholder="msg('输入开户公司结算密码')"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>
    </el-form>
</template>

<script>
export default {
    name: 'CouponSend',
    data(){
        return {
            name:null,
            weight:0,
            amount:0,
            expireTime:null,
            remark:null,
            password:null,
        }
    },
    mounted(){
    },
    props:{
        id: Number
    },
    methods:{
        init(){
            this.name = null;
            this.weight = 0;
            this.amount = 0;
            this.expireTime = null;
            this.remark = null;
            this.password = null;
        },
        onSubmit(){
            if(!this.name){
                this.$message(this.msg("请输入券名"));
                return;
            }
            if(!this.weight || this.weight <= 0){
                this.$message(this.msg("请输入重量"));
                return;
            }
            if(!this.amount || this.amount <= 0){
                this.$message(this.msg("请输入金额"));
                return;
            }
            if(!this.expireTime){
                this.$message(this.msg("请选择过期时间"));
                return;
            }
            if(!this.password){
                this.$message(this.msg("请输入结算密码"));
                return;
            }
            let param = {
                walletId:this.id,
                name:this.name,
                weight:this.weight*1000,
                amount:this.amount*100,
                expireTime:this.expireTime,
                remark:this.remark,
                password:this.password
            }
            var _this = this;
            this.$axios.post(this.$kit.api.coupon.create, param)
                .then(function(result){
                    if(result.status){
                        _this.$message(result.msg || _this.msg("创建成功"));
                        if(result.status){
                            _this.$emit("close-dg", true);
                        }
                    }else{
                        _this.$message(result.msg ||  _this.msg('创建失败'));
                    }
                });
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/ .el-autocomplete-suggestion{
    width: auto!important;
    }
</style>