<!--更新开户公司-->
<template>
    <el-form ref="form" label-width="100px" size="mini">
        <el-row>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('积分类型')">
                    <el-select v-model="type" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('积分')">
                    <!-- <el-input v-model.trim="points" :placeholder="msg('输入积分')"></el-input> -->
                    <el-input-number v-model="points" controls-position="right" :min="0"></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :xs="16" :sm="16">
                <el-form-item :label="msg('结算密码')">
                    <el-input type="password" show-password v-model.trim="password" :placeholder="msg('输入开户公司结算密码')"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>
    </el-form>
</template>

<script>
export default {
    name: 'companyUpdate',
    data(){
        return {
            options:[
                {
                    label:'赠送',
                    value:1
                },
                {
                    label:'过期',
                    value:2
                }
            ],
            type:null,
            points:0,
            password:null,
        }
    },
    props:{
        id: Number
    },
    methods:{
        init(){
            this.password = null;
            this.points = 0;
            this.type = null;
        },
        onSubmit(){
            if(!this.type){
                this.$message(this.msg("请选择积分类型"));
                return;
            }
            if(!this.points){
                this.$message(this.msg("请输入积分数量"));
                return;
            }
            if(!this.password){
                this.$message(this.msg("请输入结算密码"));
                return;
            }
            let param = {
                ids:this.id,
                type:this.type,
                points:this.points,
                password:this.password
            }
            var _this = this;
            this.$axios.post(this.$kit.api.wallet.updatePoint, param)
                .then(function(result){
                    if(result.status){
                        _this.$message(result.msg || _this.msg("创建成功"));
                        if(result.status){
                            _this.$emit("close-dg", true);
                        }
                    }else{
                        _this.$message(result.msg ||  _this.msg('创建失败'));
                    }
                });
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/ .el-autocomplete-suggestion{
    width: auto!important;
    }
</style>