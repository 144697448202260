<!-- 会员钱包-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="doView()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-chakanxiangqing" font-size="27px;"/>
                            <span class="txt" >{{msg('查看详情')}}</span>      
                        </div>                
                    </el-link>
                    <el-link v-if="hasPermission('wallet:kaihu')" :underline="false" @click="createWallet()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-kaihu" font-size="27px;"/>
                            <span class="txt" >{{msg('开户')}}</span>      
                        </div>                
                    </el-link>
                    <el-link v-if="hasPermission('wallet:updatepoint')" :underline="false" @click="updatePoints()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-jifen" font-size="27px;"/>
                            <span class="txt" >{{msg('更新积分')}}</span>      
                        </div>                
                    </el-link>
                    <el-link v-if="hasPermission('wallet:updatepoint')" :underline="false" @click="updateLoan()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-xinyongedu" font-size="27px;"/>
                            <span class="txt" >{{msg('更新信用额度')}}</span>      
                        </div>                
                    </el-link>
                    <el-link v-if="hasPermission('wallet:updatepoint')" :underline="false" @click="createCoupon()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-youhuiquan" font-size="27px;"/>
                            <span class="txt" >{{msg('优惠券')}}</span>      
                        </div>                
                    </el-link>
                    <el-link v-if="hasPermission('wallet:cashIn')" :underline="false" @click="cashIn()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-chongzhi" font-size="27px;"/>
                            <span class="txt" >{{msg('充值')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="walletRemark()" v-if="hasPermission('wallet:remark')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-beizhu" font-size="27px;"/>
                            <span class="txt">{{msg('更新钱包备注')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doExport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daochu2" font-size="27px;"/>
                            <span class="txt">{{msg('导出')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doDisable(0)">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-jinyong" font-size="27px;"/>
                            <span class="txt">{{msg('禁用')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doDisable(1)">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-jiechusuoding" font-size="27px;"/>
                            <span class="txt">{{msg('解禁')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="deleteWallet()" v-if="hasPermission('wallet:deleteWallet')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>
                        </div>            
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="100px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('姓名 账户')">
                        <!-- <el-input v-model="scope.query.name" :placeholder="msg('账号或者姓名')" clearable></el-input> -->
                        <el-input type="textarea"  :rows="3" v-model="scope.query.name" style="margin-top:0px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="msg('开户公司')">
                        <el-input v-model="scope.query.company" :placeholder="msg('输入开户公司名称')" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('组别')">
                        <el-input v-model="scope.query.userTag" :placeholder="msg('输入用户组别')" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('经办人')">
                        <el-input v-model="scope.query.createName" :placeholder="msg('输入经办人名称')" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('钱包状态')" >
                        <el-select v-model="scope.query.status" clearable>
                            <el-option :label="msg('正常')" :value="0"></el-option>
                            <el-option :label="msg('余额不足')" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('账号状态')" >
                        <el-select v-model="scope.query.userStatus" clearable>
                            <el-option v-for="d in userSelectStatus" 
                                :label="d.label" :value="d.value" :key="d.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="getWallets" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                resizable border size="mini" export-config
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="wallets"
                :radio-config="{labelField: 'name', trigger: 'row'}"
                :columns="columns"
            ></vxe-grid>

            <pagenator
                @page-change="handlerPageChange"
                :query="query"
            >
            </pagenator>
        </div>

        <el-dialog
            :title="msg('充值')"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            width="50%">
            <el-row style="text-align:center;margin-top:-15px;margin-bottom:20px;">
                <el-col :xs="12" :sm="6">
                    <span class="label">{{msg('姓名')}}：</span>
                    <span class="value">
                        <el-tag type="danger">{{dgFullname}}</el-tag>
                        <!-- <span style="color: #F56C6C">{{dgFullname}}</span> -->
                    </span>
                </el-col>
                <el-col :xs="12" :sm="6">
                    <span class="label">{{msg('账号')}}：</span>
                    <span class="value">
                        <el-tag type="danger">{{dgUsername}}</el-tag>
                        <!-- <span style="color: #F56C6C">{{dgUsername}}</span> -->
                    </span>
                </el-col>
                <el-col :xs="12" :sm="6">
                    <span class="label">{{msg('开户公司')}}：</span>
                    <span class="value">
                        <el-tag type="danger">{{dgCompanyName}}</el-tag>
                        <!-- <span style="color: #F56C6C">{{dgCompanyName}}</span> -->
                    </span>
                </el-col>
            </el-row>
            <el-form ref="form" label-width="100px" size="mini">
                <el-row>
                    <el-col :xs="24" :sm="20">
                        <el-form-item :label="msg('充值金额')">
                            <el-input type="number" v-model="amount" :placeholder="msg('请输入充值金额')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="20">
                        <el-form-item :label="msg('备注')">
                            <el-input v-model="remark" :placeholder="msg('请输入备注')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <!-- <span>
                <el-input type="number" v-model="amount" :placeholder="msg('请输入充值金额')"></el-input>
            </span>
            <span>
                <el-input style="margin-top:20px" v-model="remark" :placeholder="msg('请输入备注')"></el-input>
            </span> -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{msg('取消')}}</el-button>
                <el-button type="primary" @click="doCashIn()">{{msg('确定')}}</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="msg('开户')" :close-on-click-modal="false"  @opened="openedCreate"
            :visible.sync="dgVisible" width="600px"
            :fullscreen="isMinScreen">
                <wallet-create ref="createRef" @close-dg="closeCreateDialog" />
        </el-dialog>

        <el-dialog :title="msg('更新信用额度')" :close-on-click-modal="false"  @opened="openedUpdateLoan"
            :visible.sync="dgUpdateLoanVisible" width="600px"
            :fullscreen="isMinScreen">
                <wallet-update-loan :id="updateId" ref="updateLoanRef" @close-dg="closeUpdateLoanDialog" />
        </el-dialog>

        <el-dialog :title="msg('更新积分')" :close-on-click-modal="false"  @opened="openedUpdatePoint"
            :visible.sync="dgUpdatePointVisible" width="600px"
            :fullscreen="isMinScreen">
                <wallet-update-point :id="updateId" ref="updatePointRef" @close-dg="closeUpdatePointDialog" />
        </el-dialog>

        <el-dialog :title="msg('赠送优惠券')" :close-on-click-modal="false"  @opened="openedCreateCoupon"
            :visible.sync="dgCreateCouponVisible" width="600px"
            :fullscreen="isMinScreen">
                <wallet-coupon-send :id="updateId" ref="createCouponRef" @close-dg="closeCreateCouponDialog" />
        </el-dialog>

    </div>
   
</template>

<script>

import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Pagenator from "../../components/Pagination.vue";
import WalletCreate from "./WalletCreate.vue";
import WalletUpdateLoan from "./WalletUpdateLoan.vue";
import WalletUpdatePoint from "./WalletUpdatePoint.vue";
import WalletCouponSend from "./WalletCouponSend.vue";
export default {
    name: 'WalletList',
    components: {
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Pagenator:Pagenator,
        WalletCreate:WalletCreate,
        WalletUpdateLoan:WalletUpdateLoan,
        WalletUpdatePoint:WalletUpdatePoint,
        WalletCouponSend:WalletCouponSend
    },
    mounted(){
        this.getWallets();
        this.userSelectStatus = this.dicts.userEditStatuses;
    },
    data(){
        return {
            query:{
                url: null,
                name: null,
                userTag: null,
                createName:null,
                company:null,
                status:null,
                userStatus:null,
                length: 20, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
            },

            showQuery: false,
            dialogVisible: false,
            dgVisible:false,
            dgUpdateLoanVisible:false,
            dgUpdatePointVisible:false,
            dgCreateCouponVisible:false,
            updateId:null,
            amount: 0,
            remark: null,
            userSelectStatus:[],
            dgUsername:null,
            dgFullname:null,
            dgCompanyName:null,
           
            columns: [
                { type: 'radio', minWidth: 60,width:60 }, 
                {field: "id", title: this.msg("ID"), minWidth: 80, width:80, sortable:true},
                {field: "company", title: this.msg("开户公司"),minWidth: 120, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "fullname", title: this.msg("姓名"),minWidth: 120, sortable:true},
                {field: "username", title: this.msg("账户"),minWidth: 120, sortable:true},
                {field: "status", title: this.msg("钱包状态"),minWidth: 100, sortable:true, 
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                    slots: {
                        default: ({ row }) => {
                            let label = row.status==0?this.msg('正常'):this.msg('余额不足');
                            return [
                                <el-tag type={row.status==0?'primary':'danger'}>{label}</el-tag>
                            ];
                        },
                    },
                },
                {field: "newBanlance", title: this.msg("钱包余额"),minWidth: 100, sortable:true,
                    filters:[{data: {type: 'has', isCase: false, name: ''}}],
                    filterRender:{name: 'FilterComplex'},
                    slots: {
                        default: ({ row }) => {
                            // let balance = row.balance/100.0;
                            // balance = balance.toFixed(2);
                            let balance = row.newBanlance;
                            return [
                                <el-tag type={balance>0?'success':'danger'}>{balance}</el-tag>
                            ];
                        },
                    },
                },
                {field: "loan", title: this.msg("信用额度"),minWidth: 120, sortable:true,
                    filters:[{data: {type: 'has', isCase: false, name: ''}}],
                    filterRender:{name: 'FilterComplex'}
                },
                {field: "points", title: this.msg("积分"),minWidth: 120, sortable:true,
                    filters:[{data: {type: 'has', isCase: false, name: ''}}],
                    filterRender:{name: 'FilterComplex'}
                },
                {field: "coupon", title: this.msg("优惠券"),minWidth: 120, sortable:true,
                    filters:[{data: {type: 'has', isCase: false, name: ''}}],
                    filterRender:{name: 'FilterComplex'}
                },
                {field: "roleLabel", title: this.msg("角色"),minWidth: 100, sortable:true, 
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "userTag", title: this.msg("组别"),minWidth: 100, sortable:true, 
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "userStatus", title: this.msg("账号状态"),minWidth: 100, sortable:true, 
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                    slots: {
                        default: ({ row }) => {
                            let status = this.dicts.userStatuses.find(d => d.value === row.userStatus);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row.userStatus}</el-tag>
                            ];
                        },
                    },
                },
                {field: "createName", title: this.msg("经办人"),minWidth: 120, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "remark", title: this.msg("说明"),minWidth: 130, sortable:true, 
                    filters:[{data:''}],
                    filterRender:{name: 'FilterInput'},
                },
            ],
            wallets: [],      //列表
            curr: null,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        
        getWallets(refresh){

            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }

            if(this.query.name){
                let cs = this.query.name.split(/\s+/);
        
                let css = [];
                for(let i=0; i<cs.length; i++){
                    if(cs[i]) css.push(cs[i]);
                }
                this.query["name"] = css.join(",");
            }else{
                this.query["name"] = '';
            }

            var params = this.$kit.rebuildQuery(this.query);
            this.$axios.post(this.$kit.api.wallet.list, params)
                .then((result) => {
                    if(result.status){
                        this.wallets = result.data.list;
                        this.wallets.forEach(e => {
                            if(e.loan){
                                e.loan = (e.loan/100).toFixed(0);
                            }
                        })
                        this.query.pageTotal = result.data.total;
                    }
                });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.getWallets();
        },
        doView(){
            var selected = this.$refs.dataTable.getRadioRecord();
            if(!selected){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.$router.push("/admin/my/wallet/"+selected.userId);
        },
        createWallet(){
            this.dgVisible = true
        },
        closeCreateDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.getWallets();
            }
            this.dgVisible = false;
        },
        updatePoints(){//更新积分
            var selected = this.$refs.dataTable.getRadioRecord();
            if(!selected){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.updateId = selected.id;
            let companyId = selected.companyId;
            this.$axios.post(this.$kit.api.wallet.getOwnerCompanyIds)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        if(data.indexOf(companyId) < 0){
                            this.$message(this.msg('没有开户公司的权限！'));
                            return;
                        }else{
                            this.dgUpdatePointVisible = true;
                        }

                    }
                });

        },
        updateLoan(){//更新信用额度
            var selected = this.$refs.dataTable.getRadioRecord();
            if(!selected){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.updateId = selected.id;
            let companyId = selected.companyId;
            this.$axios.post(this.$kit.api.wallet.getOwnerCompanyIds)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        if(data.indexOf(companyId) < 0){
                            this.$message(this.msg('没有开户公司的权限！'));
                            return;
                        }else{
                            this.dgUpdateLoanVisible = true;
                        }

                    }
                });
        },
        createCoupon(){
            var selected = this.$refs.dataTable.getRadioRecord();
            if(!selected){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.updateId = selected.id;
            let companyId = selected.companyId;
            this.$axios.post(this.$kit.api.wallet.getOwnerCompanyIds)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        if(data.indexOf(companyId) < 0){
                            this.$message(this.msg('没有开户公司的权限！'));
                            return;
                        }else{
                            this.dgCreateCouponVisible = true;
                        }

                    }
                });
        },
        closeUpdateLoanDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.getWallets();
            }
            this.dgUpdateLoanVisible = false;
        },
        closeUpdatePointDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.getWallets();
            }
            this.dgUpdatePointVisible = false;
        },
        closeCreateCouponDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.getWallets();
            }
            this.dgCreateCouponVisible = false;
        },
        //直接充值
        cashIn(){
            var selected = this.$refs.dataTable.getRadioRecord();
            if(!selected){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            this.dgUsername = selected.username,
            this.dgFullname = selected.fullname,
            this.dgCompanyName = selected.company,
            this.amount = 0;
            this.remark = null;
            let companyId = selected.companyId;
            this.$axios.post(this.$kit.api.wallet.getOwnerCompanyIds)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        if(data.indexOf(companyId) < 0){
                            this.$message(this.msg('没有开户公司的权限！'));
                            return;
                        }else{
                            this.dialogVisible = true;
                        }
                    }
                });

        },
        doCashIn(){
            this.amount = this.amount*1;
            if(!this.amount){
                this.$message(this.msg('请输入金额'));
                return;
            }

            if(this.amount<=0){
                this.$message(this.msg('金额不能小于等于0'));
            }

            let selected = this.$refs.dataTable.getRadioRecord();

            this.$prompt("请输入开户公司口令", this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                inputType: 'password',
            }).then(({ value }) => {
                this.$axios.post(this.$kit.api.wallet.checkCompanyWord+"?companyId="+selected.companyId+"&pwd="+value)
                    .then((result) => {
                        if(result.status){
                            this.$axios.get(this.$kit.api.wallet.cashIn+'?id='+this.$refs.dataTable.getRadioRecord().id+"&amt="+(this.amount*100).toFixed(0)+"&remark="+(this.remark || ''))
                            .then((result) => {
                                this.$message(result.msg);
                                if(result.status){
                                    this.dialogVisible = false;
                                    this.getWallets();
                                }
                            });
                        }else{
                            this.$message(this.msg('开户公司口令不正确'));
                        }
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });

        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        doDisable(disable){
            var selected = this.$refs.dataTable.getRadioRecord();
            if(!selected){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            let name = selected.username;
            let ids = selected.id;

            this.$confirm('确定要禁用账户：'+name, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(this.$kit.api.user.disabled+"?d="+disable+"&ids="+ids)
                .then((result) => {
                    this.$message(result.msg);
                    this.getWallets();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消操作'
                });          
            });

        },
        deleteWallet(){
            var selected = this.$refs.dataTable.getRadioRecord();
            if(!selected){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selected.length > 1){
                this.$message(this.msg('只能单条删除'));
                return;
            }
            this.$confirm('确定要删除钱包吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(this.$kit.api.wallet.deleteWallet+"?id="+selected.id)
                .then((result) => {
                    this.$message(this.msg(result.msg));
                    this.getWallets();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消操作'
                });          
            });

        },
        walletRemark(){
            var selected = this.$refs.dataTable.getRadioRecord();
            if(!selected){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            let _this = this;
            let id = selected.id;
            let r = selected.remark;
            let companyId = selected.companyId;
            this.$axios.post(this.$kit.api.wallet.getOwnerCompanyIds)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        if(data.indexOf(companyId) < 0){
                            this.$message(this.msg('没有开户公司的权限！'));
                            return;
                        }else{
                            this.$prompt(_this.msg("请输入钱包备注"), _this.msg('提示'), {
                                confirmButtonText: _this.msg('确定'),
                                cancelButtonText: _this.msg('取消'),
                                inputValue: r
                            }).then(({ value }) => {
                                value = value? value: "";
                                _this.$axios.get(_this.$kit.api.wallet.remark+"?id="+id+"&r="+encodeURIComponent(value))
                                .then((result) => {
                                    _this.$message(result.msg);
                                    _this.getWallets();
                                });
                            }).catch(() => {
                                _this.$message({
                                    type: 'info',
                                    message: '取消输入'
                                });       
                            });
                        }
                    }
                });
        },
        openedCreate(){
            this.$nextTick(() => {
                this.$refs.createRef.init();
            });
        },
        openedUpdateLoan(){
            this.$nextTick(() => {
                this.$refs.updateLoanRef.init();
            });
        },
        openedUpdatePoint(){
            this.$nextTick(() => {
                this.$refs.updatePointRef.init();
            });
        },
        openedCreateCoupon(){
            this.$nextTick(() => {
                this.$refs.createCouponRef.init();
            });
        }
     
    }
    
}
</script>

<style scoped lang="scss">

</style>
